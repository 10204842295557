import React, { Component } from "react"
import WhatsappLogo from "../../images/whatsapp.png"

export default class Whatsapp extends Component {
  render() {
    return (
      <a
        href="https://api.whatsapp.com/send?phone=60137311007&text=Hi%20Arus%20Oil%20Hotline%21%20I%20would%20like%20to%20request%20for%20your%20support."
        className="fixed bottom-6 right-6 rounded-full shadow-md z-50"
        style={{ backgroundColor: "#43c252" }}
      >
        <img className="h-12 w-12 m-2" src={WhatsappLogo} alt="Whatsapp" />
      </a>
    )
  }
}
